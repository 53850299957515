
import React from "react";
import { Link, useParams } from "react-router-dom";

import Footer from "../pages/Footer";
import Notification from "./Notification";

const ExamplesIntermediate = () => {
  return (
    <>
      <section>

      <Notification />

        <div className="container-fluid alert-blue-grotto py-4">
        <p id='p-white-30' align='center'> Intermediate Examples </p>
        </div>

<div className ="container-fluid py-4">
          <div className = "row">

                <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 py-0"></div>
                <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 py-2" align='center'>
        <p id = "p-red-30" align="center"><strong>Let's continue with the random seed phrase</strong></p>
        
        <br />

<p id = "p-black-30" align="center"><>gun promote acquire labor much stamp</></p> 
<p id = "p-black-30" align="center"><>modify egg sword nothing path pause</></p>

          </div>
                <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 py-0"></div>
        </div></div>


        <div className ="container-fluid">
          <div className = "row">

                <div className="col-0 col-sm-0 col-md-0 col-lg-1 col-xl-1 py-0"></div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 py-2" align='center'>

          <p id = "p-black-30" align="center">Write them down on a clean piece of paper to follow along with the examples</p>
          
          
          </div>
                <div className="col-0 col-sm-0 col-md-0 col-lg-1 col-xl-1 py-0"></div>
        </div></div>

            <br />

            <Footer/>
        
      </section>
      
    </>
  );
};

export default ExamplesIntermediate;