
import React from "react";
import { Link, useParams } from "react-router-dom";

import Footer from "../pages/Footer";
import Notification from "./Notification";

const ExamplesAdvancedOne = () => {
  return (
    <>
      <section>

      <Notification />

        <div className="container-fluid alert-blue-grotto py-4">
        <p id='p-white-30' align='center'> Advanced Example One </p>
        </div>


            <div className ="container-fluid py-0" align="center">
          <div className = "row">

                <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 py-0"></div>
                <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 py-4">

                <p id="p-black-30" align="left"><strong> Coming Soon</strong> </p>
                                  
                <br />

                </div>

                <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 py-0"></div>
          </div></div>

            <Footer/>
        
      </section>
      
    </>
  );
};

export default ExamplesAdvancedOne;