
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
//import { React, useState, lazy, Suspense, useEffect } from "react";
//import * as React from 'react';
import { React, Suspense } from "react";
import loadable from '@loadable/component';

//import ReactDOM from "react-dom/client";
import './App.css';
//import GetNavBar from './components/GetNavBar';
import Layout from './components/pages/Layout';
//import ErrorPage from "./components/pages/ErrorPage";
//import Index from "./components/pages/Index";
import Home from "./components/pages/Home";
import Faq from "./components/pages/Faq"
import Ipfs from "./components/pages/Ipfs"
import Github from "./components/pages/Github"
import Explore from "./components/pages/ExploreAll";
import ExploreAll from "./components/pages/ExploreAll";
import ExploreByLetter from "./components/pages/ExploreByLetter";
import Roadmap from "./components/pages/Roadmap";
//import Notifications from "./components/pages/Notification";

// vaults 1 - 500
import Explore_1_100 from "./components/pages/vaults_1_500/Explore100";
import Explore_101_200 from "./components/pages/vaults_1_500/Explore200";
import Explore_201_300 from "./components/pages/vaults_1_500/Explore300";
import Explore_301_400 from "./components/pages/vaults_1_500/Explore400";
import Explore_401_500 from "./components/pages/vaults_1_500/Explore500";

// vaults 501 - 1000
import Explore_501_600 from "./components/pages/vaults_501_1000/Explore600";
import Explore_601_700 from "./components/pages/vaults_501_1000/Explore700";
import Explore_701_800 from "./components/pages/vaults_501_1000/Explore800";
import Explore_801_900 from "./components/pages/vaults_501_1000/Explore900";
import Explore_901_1000 from "./components/pages/vaults_501_1000/Explore1000";

// vaults 1001 - 1500
import Explore_1001_1100 from "./components/pages/vaults_1001_1500/Explore_1001_1100";
import Explore_1101_1200 from "./components/pages/vaults_1001_1500/Explore_1101_1200";
import Explore_1201_1300 from "./components/pages/vaults_1001_1500/Explore_1201_1300";
import Explore_1301_1400 from "./components/pages/vaults_1001_1500/Explore_1301_1400";
import Explore_1401_1500 from "./components/pages/vaults_1001_1500/Explore_1401_1500";

// vaults 1501 - 2000
import Explore_1501_1600 from "./components/pages/vaults_1501_2000/Explore_1501_1600";
import Explore_1601_1700 from "./components/pages/vaults_1501_2000/Explore_1601_1700";
import Explore_1701_1800 from "./components/pages/vaults_1501_2000/Explore_1701_1800";
import Explore_1801_1900 from "./components/pages/vaults_1501_2000/Explore_1801_1900";
import Explore_1901_2000 from "./components/pages/vaults_1501_2000/Explore_1901_2000";

// vaults 2001 - 2048
import Explore_2001_2048 from "./components/pages/vaults_2001_2048/Explore_2001_2048";

// sort by alphabet letter
import Explore_Vault_Alphabet from "./components/explore/Explore_Vault_Alphabet";
import Encoded_Vault_Alphabet from "./components/encoded/pages/Encoded_Vault_Alphabet";
import Expand_Vault_Alphabet from "./components/expand/Expand_Vault_Alphabet";


//import EncVault1 from "./components/pages/EncVault1";
//import Docs from "./components/pages/Docs";
import Examples from "./components/pages/Examples";
import ExamplesBasic from "./components/pages/ExamplesBasic";
import ExamplesBasicOne from "./components/pages/ExamplesBasicOne";
import ExamplesBasicTwo from "./components/pages/ExamplesBasicTwo";
import ExamplesBasicThree from "./components/pages/ExamplesBasicThree";
//////////////////////////////////////////////////////////////////////////
import ExamplesIntermediate from "./components/pages/ExamplesIntermediate";
import ExamplesIntermediateOne from "./components/pages/ExamplesIntermediateOne";
import ExamplesIntermediateTwo from "./components/pages/ExamplesIntermediateTwo";
import ExamplesIntermediateThree from "./components/pages/ExamplesIntermediateThree";
//////////////////////////////////////////////////////////////////////////
import ExamplesAdvanced from "./components/pages/ExamplesAdvanced";
import ExamplesAdvancedOne from "./components/pages/ExamplesAdvancedOne";
import ExamplesAdvancedTwo from "./components/pages/ExamplesAdvancedTwo";
import ExamplesAdvancedThree from "./components/pages/ExamplesAdvancedThree";
//////////////////////////////////////////////////////////////////////////
import Nft from "./components/pages/Nft";
import Vault from "./components/pages/Vault";
//import Vaults from "./components/pages/Vaults";

//////////////////////////////////////////////////////////////////////////

const Encoded_Vault = loadable(() => import('./components/encoded/pages/Encoded_Vault'));
const Encoded_Vault_All = loadable(() => import('./components/encoded/pages/Encoded_Vault_All'));

//////////////////////////////////////////////////////////////////////////

const Decoded_Vault = loadable(() => import('./components/decoded/pages/Decoded_Vault'));
const Decoded_Vault_All = loadable(() => import('./components/decoded/pages/Decoded_Vault_All'));

//////////////////////////////////////////////////////////////////////////

function App() {
  return (
    <>

    <BrowserRouter>
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>

                  <Route path="/vault/encoded/:id/:letter" element={<Encoded_Vault_Alphabet />} /> 
                  <Route path="/" element={<Layout />} >         
                  
                      <Route path="home" element={<Home />} /> 
                          // explore page route //

                      <Route path="explore/all" element={<Explore />} />                   
                      <Route path="explore/all" element={<ExploreAll />} />
                      <Route path="explore/" element={<ExploreByLetter/>} />
                          
                      <Route path="explore/1-100" element={<Explore_1_100 />} />
                      <Route path="explore/101-200" element={<Explore_101_200 />} />
                      <Route path="explore/201-300" element={<Explore_201_300 />} />
                      <Route path="explore/301-400" element={<Explore_301_400 />} />
                      <Route path="explore/401-500" element={<Explore_401_500 />} />
                      <Route path="explore/501-600" element={<Explore_501_600 />} />
                      <Route path="explore/601-700" element={<Explore_601_700 />} />
                      <Route path="explore/701-800" element={<Explore_701_800 />} />
                      <Route path="explore/801-900" element={<Explore_801_900 />} />
                      <Route path="explore/901-1000" element={<Explore_901_1000 />} />
                      <Route path="explore/1001-1100" element={<Explore_1001_1100 />} />                          
                      <Route path="explore/1101-1200" element={<Explore_1101_1200 />} />
                      <Route path="explore/1201-1300" element={<Explore_1201_1300 />} />
                      <Route path="explore/1301-1400" element={<Explore_1301_1400 />} />
                      <Route path="explore/1401-1500" element={<Explore_1401_1500 />} />
                      <Route path="explore/1501-1600" element={<Explore_1501_1600 />} />
                      <Route path="explore/1601-1700" element={<Explore_1601_1700 />} />
                      <Route path="explore/1701-1800" element={<Explore_1701_1800 />} />                        
                      <Route path="explore/1801-1900" element={<Explore_1801_1900 />} />
                      <Route path="explore/1901-2000" element={<Explore_1901_2000 />} />
                      <Route path="explore/2001-2048" element={<Explore_2001_2048 />} />


                          <Route path="explore/*" element={<ExploreAll />} />

                          <Route path="roadmap" element={<Roadmap />} />
                          <Route path="faq" element={<Faq />} />
                          <Route path="github" element={<Github />} />                   
                          <Route path="ipfs" element={<Ipfs />} />    
                          
                          // examples page route //
                      <Route path="examples" element={<Examples />} />
                      <Route path="examples/basic" element={<ExamplesBasic />} />
                          <Route path="examples/basic/1" element={<ExamplesBasicOne />} />
                          <Route path="examples/basic/2" element={<ExamplesBasicTwo />} />
                          <Route path="examples/basic/3" element={<ExamplesBasicThree />} />                                                  
                      <Route path="examples/intermediate" element={<ExamplesIntermediate />} />
                          <Route path="examples/intermediate/1" element={<ExamplesIntermediateOne />} />
                          <Route path="examples/intermediate/2" element={<ExamplesIntermediateTwo />} />
                          <Route path="examples/intermediate/3" element={<ExamplesIntermediateThree />} />   
                      <Route path="examples/advanced" element={<ExamplesAdvanced />} />
                          <Route path="examples/advanced/1" element={<ExamplesAdvancedOne />} />
                          <Route path="examples/advanced/2" element={<ExamplesAdvancedTwo />} />
                          <Route path="examples/advanced/3" element={<ExamplesAdvancedThree />} />   
                                          


                          <Route path="examples/*" element={<Home />} />           
              
                      <Route path="nft" element={<Nft />} />
                          <Route path="nft/*" element={<Home />} />
              
                      <Route path="vault" element={<Vault />} />

                          <Route path="vault/encoded/:id" element={<Encoded_Vault />} />
                          <Route path="vault/encoded/*" element={<Home />} />

                          <Route path="vault/encoded/all/:id" element={<Encoded_Vault_All />} />
                          <Route path="vault/encoded/all/*" element={<Home />} />

                          <Route path="vault/alphabet/:id" element={<Explore_Vault_Alphabet />} />
                          <Route path="vault/expand/:vault/:letter" element={<Expand_Vault_Alphabet />} />
                          <Route path="vault/expand/*" element={<Home />} />                          

                          <Route path="vault/encoded/a/:id" element={<Encoded_Vault />} />
                          <Route path="vault/encoded/a/*" element={<Home />} />

                          <Route path="vault/decoded/:id" element={<Decoded_Vault />} />
                          <Route path="vault/decoded/*" element={<Home />} />

                          <Route path="vault/decoded/all/:id" element={<Decoded_Vault_All />} />
                          <Route path="vault/decoded/all/*" element={<Home />} />

                       </Route>


      </Routes>
      </Suspense>
    </BrowserRouter>

    </>
  );
}

export default App;