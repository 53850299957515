
import React from "react";
import Notification from "../site/json/header_notifications.json";

var randNum =  Math.floor(Math.random() * 6) + 1;
console.log(randNum);

const Notifications = () => {
  console.log(Notification);


var GetAlertColor = Notification[randNum].alert;
var GetFontColor = Notification[randNum].p;
var GetFontAwesomeIcon = Notification[randNum].fa;


  return (
    <>
      <section>
      
      <div class={GetAlertColor} role="alert" align="center">
          <p id ={GetFontColor}><i class={GetFontAwesomeIcon}></i> <strong>{Notification[randNum].notification} </strong> </p>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
        
      </section>
      
    </>
  
    );
};

export default Notifications;