
import React from "react";
import { Link, useParams } from "react-router-dom";

import Footer from "../pages/Footer";
import Notification from "./Notification";

const ExamplesIntermediate = () => {
  return (
    <>
      <section>

      <Notification />

        <div className="container-fluid alert-blue-grotto py-4">
        <p id='p-white-30' align='center'> Intermediate Example </p>
        </div>


<div className ="container-fluid py-4">
          <div className = "row">

                <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 py-0"></div>
                <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 py-2" align='center'>
        <p id = "p-red-30" align="center"><strong>Let's continue with the random seed phrase</strong></p>
        
        <br />

<p id = "p-black-30" align="center"><>gun promote acquire labor much stamp</></p> 
<p id = "p-black-30" align="center"><>modify egg sword nothing path pause</></p>

          </div>
                <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 py-0"></div>
        </div></div>

                <br />

      <div class="alert alert-danger alert-dismissible fade show" role="alert" align="center">
          <p id ="p-red-20"><i class="fas fa-times-circle"></i> <strong>This Example is for Eduction Purposes Only </strong> </p>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
      <div class="alert alert-danger alert-dismissible fade show" role="alert" align="center">
          <p id ="p-red-20"><i class="fas fa-times-circle"></i> <strong>Do Not use this Seed Phrase as your own Wallet </strong> </p>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
      
            <div className ="container-fluid py-0" align="center">
          <div className = "row">

                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 py-4">

                  <div className="container alert alert-red py-2"><p id="p-white-30"><a class="examples" href="../../examples/intermediate/1"> Intermediate Example One </a></p></div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 py-4">
                  <div className="container"></div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 py-4">
                  <div className="container alert alert-blue-grotto py-2"><p id="p-blue-30"><a class="examples" href="../../examples/"> Back to Examples</a></p></div>
                </div>

          </div></div>


            <br />

            <Footer/>
        
      </section>
      
    </>
  );
};

export default ExamplesIntermediate;