import React from "react";
//import enc_vault_1 from "../../json/enc/EncVault1.json";

const Vault = () => {
  return (
    <>
      <section>

        <div className="container-fluid alert alert-light-blue">
        <p id='p-blue-40' align='center'>
          <strong> Vault </strong>
        </p>
        </div>

      </section>
      
    </>
  );
};

export default Vault;