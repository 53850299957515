
import React from "react";
//import enc_vault_1 from "../../json/enc/EncVault1.json";

import Footer from "../pages/Footer";
import Notification from "./Notification";
//import ExampleWarning from "./ExampleWarning";
import Layout from "./Layout";

const Roadmap = () => {
  return (
    <>
      <section>

        <Notification />

        <div className="container-fluid alert-blue-grotto py-0">
        <p id='p-white-25' align='center'> Project Roadmap</p>
        </div>

        <div className ="container-fluid py-0">
          <div className = "row">

<section class="bsb-timeline-4 bg-light py-3 py-md-5 py-xl-8">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-10 col-md-12 col-xl-10 col-xxl-9">

        <ul class="timeline">
          <li class="timeline-item right">
            <div class="timeline-body">
              <div class="timeline-meta">
                <div class="d-inline-flex flex-column px-2 py-1 text-success-emphasis bg-success-subtle border border-success-subtle rounded-2 text-md-end">
                  <span>Early July 2021</span>
                </div>
              </div>
              <div class="timeline-content timeline-indicator">
                <div class="card border-0 shadow">
                  <div class="card-body p-xl-4">
                    <h2 class="card-title mb-2">Seed Phrase Vault</h2>
                    <h6 class="card-subtitle text-secondary mb-3">The idea was born</h6>
                    <p class="card-text m-0">Planning, Research and Development of the project began</p>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="timeline-item left">
            <div class="timeline-body">
              <div class="timeline-meta">
                <div class="d-inline-flex flex-column px-2 py-1 text-success-emphasis bg-success-subtle border border-success-subtle rounded-2 text-md-end">
                  <span>Launched June 2024</span>
                </div>
              </div>
              <div class="timeline-content timeline-indicator">
                <div class="card border-0 shadow">
                  <div class="card-body p-xl-4">
                    <h2 class="card-title mb-2">Galaxis Community</h2>
                    <h6 class="card-subtitle text-secondary mb-3">Community Card</h6>
                    <p class="card-text m-0">After some development (many, many hours) - Our project was announced to the world on the newly launched Galaxis Platform.</p>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="timeline-item right">
            <div class="timeline-body">
              <div class="timeline-meta">
                <div class="d-inline-flex flex-column px-2 py-1 text-success-emphasis bg-success-subtle border border-success-subtle rounded-2 text-md-end">
                  <span>Early August 2024</span>
                </div>
              </div>
              <div class="timeline-content timeline-indicator">
                <div class="card border-0 shadow">
                  <div class="card-body p-xl-4">
                    <h2 class="card-title mb-2">SeedPhraseVault.xyz</h2>
                    <h6 class="card-subtitle text-secondary mb-3">Release v1.3</h6>
                    <p class="card-text m-0">Update UI based on Early Community Member Feedback.</p>
                  </div>
                </div>
              </div>
            </div>
          </li>
                    <li class="timeline-item left">
            <div class="timeline-body">
              <div class="timeline-meta">
                <div class="d-inline-flex flex-column px-2 py-1 text-success-emphasis bg-success-subtle border border-success-subtle rounded-2 text-md-end">
                  <span>Early August 2024</span>
                </div>
              </div>
              <div class="timeline-content timeline-indicator">
                <div class="card border-0 shadow">
                  <div class="card-body p-xl-4">
                    <h2 class="card-title mb-2">SP Vaults</h2>
                    <h6 class="card-subtitle text-secondary mb-3">Uploaded to IPFS</h6>
                    <p class="card-text m-0">Vital Step in Decentralisation - All 2048 Vaults uploaded and pinned on IPFS.</p>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="timeline-item right">
            <div class="timeline-body">
              <div class="timeline-meta">
                <div class="d-inline-flex flex-column px-2 py-1 text-success-emphasis bg-success-subtle border border-success-subtle rounded-2 text-md-end">
                  <span>Early August 2024</span>
                </div>
              </div>
              <div class="timeline-content timeline-indicator">
                <div class="card border-0 shadow">
                  <div class="card-body p-xl-4">
                    <h2 class="card-title mb-2">Github Repositories</h2>
                    <h6 class="card-subtitle text-secondary mb-3">MIT and Apache 2 Licence</h6>
                    <p class="card-text m-0">We released all 2048 SP Vaults on the projects <a href="https://github.com/SeedPhraseVault" target="_blank" rel="noopener noreferrer">Github</a> page</p>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="timeline-item left">
            <div class="timeline-body">
              <div class="timeline-meta">
                <div class="d-inline-flex flex-column px-2 py-1 text-success-emphasis bg-success-subtle border border-success-subtle rounded-2 text-md-end">
                  <span>Early August 2024</span>
                </div>
              </div>
              <div class="timeline-content timeline-indicator">
                <div class="card border-0 shadow">
                  <div class="card-body p-xl-4">
                    <h2 class="card-title mb-2">Self-Hosted Toolbox</h2>
                    <h6 class="card-subtitle text-secondary mb-3">v1.0</h6>
                    <p class="card-text m-0">Launched a simple set of HTML providing you will sovereignty and indepenence when using the SP Vaults. Find out more <a href = "https://github.com/SeedPhraseVault/SPV-Self-Hosted-Toolbox" target="_blank" noreferrer noopener>here</a></p>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="timeline-item right">
            <div class="timeline-body">
              <div class="timeline-meta">
                <div class="d-inline-flex flex-column px-2 py-1 text-success-emphasis bg-success-subtle border border-success-subtle rounded-2 text-md-end">
                  <span>Planned Late August 2024</span>
                </div>
              </div>
              <div class="timeline-content timeline-indicator">
                <div class="card border-0 shadow">
                  <div class="card-body p-xl-4">
                    <h2 class="card-title mb-2">YouTube Channel</h2>
                    <h6 class="card-subtitle text-secondary mb-3">Training Videos</h6>
                    <p class="card-text m-0">Training and Informational Videos will be made available on our <a href="https://www.youtube.com/@seedphrasevault" target="_blank">YouTube Channel</a></p>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="timeline-item left">
            <div class="timeline-body">
              <div class="timeline-meta">
                <div class="d-inline-flex flex-column px-2 py-1 text-success-emphasis bg-success-subtle border border-success-subtle rounded-2 text-md-end">
                  <span>September 2024 onwards</span>
                </div>
              </div>
              <div class="timeline-content timeline-indicator">
                <div class="card border-0 shadow">
                  <div class="card-body p-xl-4">
                    <h2 class="card-title mb-2">Regular Updates</h2>
                    <h6 class="card-subtitle text-secondary mb-3"></h6>
                    <p class="card-text m-0">We have many more exciting annoucement coming soon - Mint a <a href="https://galaxis-community.com/communities/seed-phrase-vault" target="_blank">Free Community Card on Galaxis</a> or Follow us on <a href = "https://www.x.com/seedphrasevault" target="_blank">X</a> for regular updates.</p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>













          </div></div>



            <Footer/>
        
      </section>
      
    </>
  );
};

export default Roadmap;