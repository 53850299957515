
import React from "react";
import { Link, useParams } from "react-router-dom";

import Footer from "../pages/Footer";
import Notification from "./Notification";

const ExamplesBasicTwo = () => {
  return (
    <>
      <section>

      <Notification />

        <div className="container-fluid alert-blue-grotto py-4">
        <p id='p-white-30' align='center'> Basic Example Two </p>
        </div>


<div className ="container-fluid py-0" align="center">
          <div className = "row">

                <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 py-0"></div>
                <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 py-4">

<p id="p-black-30" align="left">Firstly we need a unique piece of information (key) to encode the seed phrase(s). </p>

<br />

<p id="p-black-30" align="left">Let's use Elon Musk's year of birth => 1971. We can then simply use vault <a href="../../vault/encoded/all/1971" target="_blank">1971</a></p>
                  
                  <br />

                  <p id="p-black-30" align="left">We can encode the seed phrase to the following </p>

                  <br />

                  <p id="p-black-30" align="left"><strong>551 425 562 394 1127 1942 362 1342 1245 481 1414 755</strong></p>

                  <br />

                  <p id="p-black-30" align="left">This can be decoded using the same vault <a href="../../vault/decoded/all/1971" target="_blank">1971</a></p>

                  <br />

                  <p id="p-black-30" align="left"><a href="../../examples/basic/">Back to Examples</a></p>

                </div>

                <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 py-0"></div>
          </div></div>

        <Footer/>
        
      </section>
      
    </>
  );
};

export default ExamplesBasicTwo;