
import React from "react";
//import enc_vault_1 from "../../json/enc/EncVault1.json";

import Footer from "../pages/Footer";
import Notification from "./Notification";
//import ExampleWarning from "./ExampleWarning";
import Layout from "./Layout";

const Examples = () => {
  return (
    <>
      <section>

        <Notification />

        <div className="container-fluid alert-blue-grotto py-0">
        <p id='p-white-25' align='center'> How to Examples</p>
        </div>

        <div className ="container-fluid py-4">
          <div className = "row">

                <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 py-0"></div>
                <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 py-2" align='center'>
        <p id = "p-red-25" align="center"><strong>Below is a 12 word seed phrase generated for this example</strong></p>
        
        <br />

<p id = "p-black-25" align="center"><>gun promote acquire labor much stamp</></p> 
<p id = "p-black-25" align="center"><>modify egg sword nothing path pause</></p>

          </div>
                <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 py-0"></div>
        </div></div>


        <div className ="container-fluid">
          <div className = "row">

                <div className="col-0 col-sm-0 col-md-0 col-lg-1 col-xl-1 py-0"></div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 py-2" align='center'>

          <p id = "p-black-25" align="center">Write them down on a clean piece of paper to follow along with the examples</p>

          </div>
                <div className="col-0 col-sm-0 col-md-0 col-lg-1 col-xl-1 py-0"></div>
        </div></div>

      <br />

      <div class="alert alert-danger alert-dismissible fade show" role="alert" align="center">
          <p id ="p-red-20"><i class="fas fa-times-circle"></i> <strong>This Example is for Eduction Purposes Only </strong> </p>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
      <div class="alert alert-danger alert-dismissible fade show" role="alert" align="center">
          <p id ="p-red-20"><i class="fas fa-times-circle"></i> <strong>Do Not use this Seed Phrase as your own Wallet </strong> </p>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
            <div className ="container-fluid py-0" align="center">
          <div className = "row">

                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 py-4">

                  <div className="container alert alert-red py-2"><p id="p-white-30"><a class="examples" href="../examples/basic"> Basic Example</a></p></div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 py-4">
                  <div className="container alert alert-dark-blue py-2"><p id="p-white-30"><a class="examples" href="../examples/intermediate"> Intermediate Example</a></p></div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 py-4">
                  <div className="container alert alert-blue-grotto py-2"><p id="p-blue-30"><a class="documentation" href="../examples/advanced"> Advanced Example</a></p></div>
                </div>

          </div></div>



            <Footer/>
        
      </section>
      
    </>
  );
};

export default Examples;