
import React from "react";
import { Link, useParams } from "react-router-dom";
import Vaults from "../site/json/vaults_alphabet.json";
import Notification from "./Notification";
import Footer from "./Footer";

const ExploreByLetter = () => {
  
  return (
    <>
      <section>

      <Notification />
    
        <div className="container-fluid alert-blue-grotto py-4">
        <p id='p-white-30' align='center'>
           ?? Explore all 2048 Seed Phrase Vault(s)
        </p>
        </div>

        <br />

      <div className ="container">
          <div className = "row">

              {Vaults.map((Vaults) => {
                return (

                <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 py-2" align='center'>
                  <div key={Vaults.id}>
                  <div className="WordDiv alert-light-blue"> <a href={Vaults.url}><p id="p-blue-25"> {Vaults.letter} </p></a> </div>
                  </div></div>
                );
              })}

          </div>
        </div>

        <br />

        <Footer />
        
      </section>
      
    </>
  
    );
};

export default ExploreByLetter;