
import React from "react";
//import GetNotification from "../site/json/header_notifications.json";
//
//import Layout from "./Layout";
import Notification from "./Notification";
//
import Footer from "./Footer";
//import Sponsor from "./Sponsor";

const Home = () => {
  return (
    <>
      <section>    

        <Notification />

        <div className="container-fluid alert-blue-grotto py-0">
        <p id='p-white-25' align='center'> Welcome </p>
        </div>

        <div className ="container-fluid py-0">
          <div className = "row">

                <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 py-0"></div>
                <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 py-4" align='center'>
        <p id = "p-red-25" align="center"><strong>As Self-Custodial wallet owners, We all face this same dilemma!</strong></p>
          <p id = "p-red-25" align="center"><strong>How do we safely record our Seed Phrase(s)?</strong></p>
          </div>
                <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 py-0"></div>
        </div></div>


        <div className ="container-fluid">
          <div className = "row">

                <div className="col-0 col-sm-0 col-md-0 col-lg-1 col-xl-1 py-0"></div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 py-0" align='center'>

          <p id = "p-black-25" align="center">Here are a cool set of tools to help achieve this ...</p>
          </div>
                <div className="col-0 col-sm-0 col-md-0 col-lg-1 col-xl-1 py-0"></div>
        </div></div>

          <br />

                <div className ="container-fluid py-0" align="center">
          <div className = "row">

                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 py-0">

                  <div className="container alert alert-red py-0"><p id="p-white-25"><a class="explore" href="../explore/all"> Explore</a></p></div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 py-0">
                  <div className="container alert alert-dark-blue py-0"><p id="p-white-25"><a class="explore" href="../examples/"> Examples</a></p></div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 py-0">
                  <div className="container alert alert-blue-grotto py-0"><p id="p-white-25"><a class="explore" href="../nft/"> Our Community Card</a></p></div>
                </div>

          </div></div>

          <br />

          <Footer />

      </section>
      
    </>
  );
};

export default Home;