
import React from "react";
import { Link, useParams } from "react-router-dom";

import Footer from "../pages/Footer";
import Notification from "./Notification";
import NFTImage from '../../images/spv-1080-1440-cc.png';



const NFT = () => {
  return (
    <>
      <section>

      <Notification />

        <div className="container-fluid alert-blue-grotto py-0">
        <p id='p-white-25' align='center'> Mint a Free Community Card on Galaxis </p>
        </div>

            <div className ="container-fluid py-0" align="center">
          <div className = "row">

                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 py-4">

                  <div className="container"></div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 py-4">
                  <div className="container"><a href="https://galaxis-community.com/communities/seed-phrase-vault" target="_blank"><img src={NFTImage} alt="SPVNFT logo" width="300" /></a></div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 py-4">
                  <div className="container"></div>
                </div>

          </div></div>


            <br />

            <Footer/>
        
      </section>
      
    </>
  );
};

export default NFT;