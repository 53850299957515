
import React from "react";
import { Link, useParams } from "react-router-dom";
import Notification from "./Notification";
import Footer from "./Footer";

const Github = () => {
  
  return (
    <>
      <section>

      <Notification />
    
        <div className="container-fluid alert-blue-grotto py-0">
        <p id='p-white-25' align='center'>
           Our Github Repository
        </p>
        </div>

        <br />

      <div className ="container">
          <div className = "row">

          <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 py-0"></div>
          <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 py-2">
          <p id = "p-black-25">Our <a href="https://github.com/SeedPhraseVault" target="_blank">Github Repository</a> has a copy of each of the 2048 vaults in json format. We have also release our <a href="https://github.com/SeedPhraseVault/SPV-Self-Hosted-Toolbox" target="_blank">Self-Hosting Toolbox v1.0</a> that allows you to access the Seed Phrase Vaults (stored on IPFS) directly from your own computer without our website. </p>
          
          <br />
          <p id = "p-black-25">All Seed Phrase Vaults and code on our Github is release under MIT and Apache 2 Licence, meaning you can used it and / or modify it as you wish. </p>
          </div>
          <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 py-0"></div>
          </div>
      </div>



        <br />

        <Footer />
        
      </section>
      
    </>
  
    );
};

export default Github;