import React  from 'react';
import { Outlet, Link } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Card from 'react-bootstrap/Card';

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Layout = () => {
  return (
    <>
        <Navbar bg="light" expand="lg">
          <Container>
            <Navbar.Brand href="/home"><p id = "p-blue-25"> <strong> Seed Phrase Vault </strong> </p></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                
                <Nav.Link href="/home"><p id = "p-black-20"> Home </p></Nav.Link>
                <Nav.Link href="/explore/all"><p id = "p-black-20"> Explore </p></Nav.Link>    
                <Nav.Link href="/examples"><p id = "p-black-20"> Examples </p></Nav.Link>
                <Nav.Link href="/faq"><p id = "p-black-20"> FAQs </p></Nav.Link> 
                <Nav.Link href="/github"><p id = "p-black-20"> Github </p></Nav.Link>                               
                <Nav.Link href="/ipfs"><p id = "p-black-20"> IPFS </p></Nav.Link>                
                <Nav.Link href="/roadmap"><p id = "p-black-20"> Roadmap </p></Nav.Link>
                <Nav.Link href="/nft"><p id = "p-black-20"> Our NFT </p></Nav.Link>
              
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

      <Outlet />
    </>
  )
};

export default Layout;