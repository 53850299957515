
// [{"json_id":"1","alphabet_vault":"76","url":"vault/expand/76/a"}

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
//import Timer from "../../pages/Timer";

import Footer from "../pages/Footer";
import Notification from "../pages/Notification";

function Explore_Vault_Alphabet() {

  const [post, setPost] = useState([]);
  const {json_id, alphabet_letter, alphabet_vault, url, id} = useParams();
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    axios.get("https://bafybeifthsetjhpp6s7v5d4odovg765bqq6mqojzrhhnf3sqlf7abeu4ia.ipfs.w3s.link/vault_"+id+"_a_z.json").then((data) => {
    console.log(data);
    setPost(data?.data);
    });
    }, [id]);

  /* ended here */

  return (

<section>

<Notification />

<div className ="container">
    <div className = "row">

<div className="col-6 col-sm-6 col-md-6 col-lg-10 col-xl-10 py-0"></div>
<div className="col-6 col-sm-6 col-md-6 col-lg-2 col-xl-2 py-2" align="center">
<div className="alert-dark-blue">
<Link to={"../explore/all"}> <p id="p-white-25"> All Vault(s) </p></Link>
</div></div>


</div></div>

<br />

<React.Fragment>
<div className="container-fluid alert-blue-grotto py-0">
<p id='p-white-30' align='center'> 
Search Vault #{id} by First Letter
</p>
</div>
</React.Fragment>

<br />

<div className ="container">
    <div className = "row">


{post.map((e) => {
return (
<div className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 py-2" align="center">
<div className="#KeyPair" key={e.alphabet_vault} >

<div>

        <div className="alert-light-blue"> <p id="p-blue-25"><Link to={e.url}> {e.alphabet_letter} </Link></p> </div>

    </div>


</div></div>
);
})}

</div></div>

<br />

<Footer />

</section>

  );
}

export default Explore_Vault_Alphabet;