
import React from "react";

const Footer = () => {
  
  return (
    <>
      <section>

        <div className="container-fluid alert-light-blue py-2">

             <>
             <p id = "p-black-25" align = "center">
             built with&nbsp;<i class="fas fa-heart" style={{ color:'red'}}></i>&nbsp;and&nbsp;<i class="fas fa-beer"></i> by <a href="https://www.x.com/daveronin" target="_blank" rel="noopener noreferrer"> @daveronin </a> 
             | Release v1.4.1 | 
             Connect &nbsp;<a href="https://www.x.com/seedphrasevault" target="_blank" rel="noopener noreferrer">
             <i class="fab fa-twitter-square" style={{ color:'blue'}}></i></a>&nbsp;
             <a href="https://github.com/SeedPhraseVault" target="_blank" rel="noopener noreferrer">
             <i class="fab fa-github" style={{ color: 'black'}}></i></a>&nbsp;
             <a href="https://www.youtube.com/@seedphrasevault" target="_blank" rel="noopener noreferrer">
             <i class="fab fa-youtube" style={{ color: 'red'}}></i></a>&nbsp;
             </p></>
 
        </div>     
 
      </section>
      
    </>
  
    );
};

export default Footer;