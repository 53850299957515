
import React from "react";
import { Link, useParams } from "react-router-dom";

import Footer from "../pages/Footer";
import Notification from "./Notification";

const ExamplesIntermediateOne = () => {
  return (
    <>
      <section>

      <Notification />

        <div className="container-fluid alert-blue-grotto py-4">
        <p id='p-white-30' align='center'> Intermediate Example One </p>
        </div>


            <div className ="container-fluid py-0" align="center">
          <div className = "row">

                <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 py-0"></div>
                <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 py-4">

<p id="p-black-30" align="left">Firstly we need a unique piece of information (key) to encode the seed phrase(s). </p>

<br />

<p id="p-black-30" align="left">Let's choose a 4 digit number for example a PIN number => <strong>9687</strong> </p>
                  
                  <br />

                  <p id="p-black-30" align="left">Let's get creative and use the pin to create 4 different numbers</p>

                  <br />

                  <p id="p-black-30" align="left">Using the pin - 1st number is <strong>9</strong>, 1st two is <strong>96</strong>, 1st three is <strong>968</strong> and last three is <strong>687</strong></p>

                  <br />

                  <p id="p-black-30" align="left">Map the 4 numbers to Vaults <strong><a href="../../vault/encoded/all/9" target="_blank">9</a></strong>, <strong><a href="../../vault/encoded/all/96" target="_blank">96</a></strong>, <strong><a href="../../vault/encoded/all/968" target="_blank">968</a></strong> and <strong><a href="../../vault/encoded/all/687" target="_blank">687</a></strong></p>

                  <br />

                  <p id = "p-black-30" align="left">Now we can take our original seed phrase and break it into 4 groups</p>

                  <br />

                  <p id = "p-black-30" align="left"><strong>Group 1 </strong> - 1st three words <strong>gun promote acquire</strong></p>
                  <p id = "p-black-30" align="left"><strong>Group 2 </strong> - next three words <strong>labor much stamp</strong></p> 
                  <p id = "p-black-30" align="left"><strong>Group 3 </strong> - next three words <strong>modify egg sword</strong></p>
                  <p id = "p-black-30" align="left"><strong>Group 4 </strong> - next three words <strong>nothing path pause</strong></p>
                  
                  <br />

                  <p id = "p-black-30" align="left">Now we have <strong>4 keys</strong> and <strong>4 distinct groups of words</strong></p>

                  <br />

                  <p id = "p-black-30" align="left">Next we assign a Vault (SPV) to each Group (GRP)</p>

                  <br />

                  <p id = "p-black-30" align="left"><strong> <a href="../../vault/encoded/all/9" target="_blank">SPV-9</a> to GRP-1</strong>, <strong> <a href="../../vault/encoded/all/96" target="_blank">SPV-96</a> to GRP-2</strong>, <strong> <a href="../../vault/encoded/all/968" target="_blank">SPV-968</a> to GPR-3 </strong> and <strong> <a href="../../vault/encoded/all/687" target="_blank">SPV-687</a> to GRP-4</strong></p>

                  <br />

                  <p id = "p-black-30" align="left">We can encode the seed phrase swapping the words for numbers for each vault assigned encoded to the following : </p>

                  <br />

                  <p id = "p-black-30" align="left"><strong>1194 1068 1205 </strong> (using <a href="../../vault/encoded/all/9" target="_blank">SPV-9</a>)</p>

                  <br />

                  <p id = "p-black-30" align="left"><strong>274 1007 1822 </strong>(using <a href="../../vault/encoded/all/96" target="_blank">SPV-96</a>)</p>

                  <br />

                  <p id = "p-black-30" align="left"><strong>1 981 884 </strong>(using <a href="../../vault/encoded/all/968" target="_blank">SPV-968</a>)</p>

                  <br />

                  <p id = "p-black-30" align="left"><strong>742 1675 1016 </strong>(using <a href="../../vault/encoded/all/687" target="_blank">SPV-687</a>)</p>

                  <br />

                  <p id = "p-black-30" align="left">So the encoded values would be </p>

                  <br />

                  <p id = "p-black-30" align="left"><strong>1194 1068 1205 274 1007 1822 1 981 884 742 1675 1016</strong></p>

                  <br />

                  <p id = "p-black-30" align="left">Decoding Vaults can be found here <strong><a href="../../vault/decoded/all/9" target="_blank">9</a>, <a href="../../vault/decoded/all/96" target="_blank">96</a>, <a href="../../vault/decoded/all/968" target="_blank">968</a>, <a href="../../vault/decoded/all/687" target="_blank">687</a></strong></p>


                <br />

      <div class="alert alert-danger alert-dismissible fade show" role="alert" align="center">
          <p id ="p-red-20"><i class="fas fa-times-circle"></i> <strong>This Example is for Eduction Purposes Only </strong> </p>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
      <div class="alert alert-danger alert-dismissible fade show" role="alert" align="center">
          <p id ="p-red-20"><i class="fas fa-times-circle"></i> <strong>Do Not use this Seed Phrase as your own Wallet </strong> </p>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
      
                  <br />

                  <p id="p-black-30" align="left"><a href="../../examples/basic/">Back to Examples</a></p>

                </div>

                <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 py-0"></div>
          </div></div>

            <Footer/>
        
      </section>
      
    </>
  );
};

export default ExamplesIntermediateOne;