
import React from "react";
import { Link, useParams } from "react-router-dom";

import Footer from "../pages/Footer";
import Notification from "./Notification";

const ExamplesBasicThree = () => {
  return (
    <>
      <section>

      <Notification />

        <div className="container-fluid alert-blue-grotto py-4">
        <p id='p-white-30' align='center'> Basic Example Three </p>
        </div>


<div className ="container-fluid py-4">
          <div className = "row">

                <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 py-0"></div>
                <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 py-2" align='center'>
        <p id = "p-red-30" align="center"><strong>Let's continue with the random seed phrase</strong></p>
        
        <br />

<p id = "p-black-30" align="center"><>gun promote acquire labor much stamp</></p> 
<p id = "p-black-30" align="center"><>modify egg sword nothing path pause</></p>

          </div>
                <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 py-0"></div>
        </div></div>


            <div className ="container-fluid py-0" align="center">
          <div className = "row">

                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 py-4">

                  <div className="container alert alert-red py-2"><p id="p-white-30"> Example One </p></div>

                  <br />

                  <p id="p-black-20" align="left">Firstly we need a unique piece of information (key) to encode your seed phrase(s). Let's use Elon Musk's year of birth => 1971. We can then use vault 1971 <a href="../vault/encoded/all/1971" target="_blank">here</a></p>
                  
                  <br />

                  <p id="p-black-20" align="left">We can encode our seed phrase to the following </p>

                  <br />

                  <p id="p-black-20" align="left"><strong>551 425 562 394 1127 1942 362 1342 1245 481 1414 755</strong></p>

                  <br />

                  <p id="p-black-20" align="left">This can be decoded using the vault <a href="../vault/decoded/all/1971" target="_blank">decoding page here</a></p>


                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 py-4">
                  <div className="container alert alert-dark-blue py-2"><p id="p-white-30">Example Two </p></div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 py-4">
                  <div className="container alert alert-blue-grotto py-2"><p id="p-blue-30"><a class="documentation" href="../examples/advanced"> Advanced Examples</a></p></div>
                </div>

          </div></div>


            <br />

            <Footer/>
        
      </section>
      
    </>
  );
};

export default ExamplesBasicThree;