
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
//import Timer from "../../pages/Timer";

import Footer from "../../pages/Footer";
import Notification from "../../pages/Notification";

//import Toggle from "../../pages/Toggle";
//import { Switch } from "@material-tailwind/react";
//import ToggleSwitch from "../../pages/ToggleSwitch";


function Encoded_Vault_Alphabet() {

  const [post, setPost] = useState([]);
  const {id, letter} = useParams();
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    axios.get("https://www.dsnn.net/spv/json/alphabet/"+id).then((data) => {
    //https://bafybeifltqs4f3em3zd3ji3aon3had7rgtvbzqodetvl3skjkm2q3jce2u.ipfs.nftstorage.link/1.json
    //axios.get("https://bafybeifltqs4f3em3zd3ji3aon3had7rgtvbzqodetvl3skjkm2q3jce2u.ipfs.w3s.link/"+id+".json").then((data) => {
    console.log(data);
    setPost(data?.data);
    });
    }, [id]);


  /* ended here */

  return (

<section>

<Notification />

<React.Fragment>
<div className="container-fluid alert-blue-grotto py-0">
<p id='p-white-30' align='center'> 
Vault {id} - Encoded
</p>
</div>
</React.Fragment>


<div className ="container-fluid py-4">
  <div className = "row">

      <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 py-0"></div>
      <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 py-0" align='center'>
<p id = "p-blue-25" align="center">
<strong>Phrase</strong> (word) and <strong>Decode Key</strong> (number) for this vault will alternate visually </p>
</div>
      <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 py-0"></div>
</div></div>

<div className ="container">
    <div className = "row">
<div className="col-6 col-sm-6 col-md-10 col-lg-10 col-xl-10 py-2">
</div>
<div className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 py-2" align="center">
<div className="alert-dark-blue">
<Link to={"../vault/decoded/all/" + id}> <p id="p-white-25"> decode </p></Link>
</div>
</div>
</div></div>

<div className ="container">
    <div className = "row">


{post.map((e) => {
return (
<div className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 py-2" align="center">
<div className="#KeyPair" key={e.id} >

<div>
      {isVisible ? (
        <div className="alert-light-blue"> <p id="p-blue-25"> {e.bip39_word} </p> </div>
      ) : (
        <div className="alert-light-blue"> <p id="p-blue-25"> {e.encoded_id} </p> </div>
      )}
    </div>


</div></div>
);
})}

</div></div>





<br />

<Footer />

</section>

  );
}

export default Encoded_Vault_Alphabet;