


import React from "react";
import { Link, useParams } from "react-router-dom";
import Faqs from "../site/json/faq.json";
import Notification from "./Notification";
import Footer from "./Footer";
import DOMPurify from 'dompurify';

const Faq = () => {

  
  return (
    <>
      <section>

      <Notification />
    
        <div className="container-fluid alert-blue-grotto py-0">
        <p id='p-white-25' align='center'>
           Frequently Asked Questions
        </p>
        </div>

        <br />

      <div className ="container">
          <div className = "row">
          <div className="col-0 col-sm-0 col-md-0 col-lg-1 col-xl-1 py-0"></div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 py-2">


              {Faqs.map((Faqs) => {
                return (

                  <div key={Faqs.id}>

                  <p id="p-blue-25"><strong>  <div
      dangerouslySetInnerHTML={{__html: Faqs.question}}
    /> </strong></p>
                  <br />
                  <p id="p-black-25">  <div
      dangerouslySetInnerHTML={{__html: Faqs.answer}}
    /> </p>


                  <br />

                  </div>
                
                );
              })}
              </div>
          <div className="col-0 col-sm-0 col-md-0 col-lg-1 col-xl-1 py-0"></div>            
          </div>
        </div>

        <br />

        <Footer />
        
      </section>
      
    </>
  
    );
};

export default Faq;