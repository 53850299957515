
import React from "react";
import { Link, useParams } from "react-router-dom";
import Notification from "./Notification";
import Footer from "./Footer";

const Ipfs = () => {
  
  return (
    <>
      <section>

      <Notification />
    
        <div className="container-fluid alert-blue-grotto py-0">
        <p id='p-white-25' align='center'>
           Interplanetary File System (IPFS)
        </p>
        </div>

        <br />

      <div className ="container">
          <div className = "row">

          <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 py-0"></div>
          <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 py-2">
          <p id = "p-black-25">The <strong>InterPlanetary File System</strong> (IPFS) is a decentralised file sharing network. </p>

          <br />

          <p id = "p-black-25">We harness this technology to host (pin) our SPV's on IPFS. This means not only are they immutable, they are also always available to be accessed. </p>
            
          <br />

          <p id = "p-black-25">https://bafybeifltqs4f3em3zd3ji3aon3had7rgtvbzqodetvl3skjkm2q3jce2u.ipfs.w3s.link/</p>

          <br />

          <p id="p-black-25">To access a specific vault, simply add the <strong>vaultnumber.json</strong> to the URL above </p>

          <br />

          <p id = "p-black-25"><strong>Example(s) below</strong></p>

          <br />


<div class="container-fluid alert alert-light-blue" align="center">
<br />
<span class="badge text-bg-secondary"><p id = "p-white-25"><strong> &nbsp; Vault 1 </strong> - Note the 1.json at the end of the link below &nbsp;</p></span>

<br /><br />

<p id = "p-black-20"><a href="https://bafybeifltqs4f3em3zd3ji3aon3had7rgtvbzqodetvl3skjkm2q3jce2u.ipfs.w3s.link/1.json" target="_blank">https://bafybeifltqs4f3em3zd3ji3aon3had7rgtvbzqodetvl3skjkm2q3jce2u.ipfs.w3s.link/1.json</a> </p>

</div>

<br />

<div class="container-fluid alert alert-light-blue" align="center">
<br />
<span class="badge text-bg-secondary"><p id = "p-white-25"><strong> &nbsp; Vault 99 </strong> - Note the 99.json at the end of the link below &nbsp;</p></span>
<br /><br />

<p id = "p-black-20"><a href="https://bafybeifltqs4f3em3zd3ji3aon3had7rgtvbzqodetvl3skjkm2q3jce2u.ipfs.w3s.link/99.json" target="_blank">https://bafybeifltqs4f3em3zd3ji3aon3had7rgtvbzqodetvl3skjkm2q3jce2u.ipfs.w3s.link/99.json</a> </p>

</div>

<br />

<div class="container-fluid alert alert-light-blue" align="center">
<br />
<span class="badge text-bg-secondary"><p id = "p-white-25"><strong> &nbsp; Vault 333 </strong> - Note the 333.json at the end of the link below &nbsp;</p></span>

<br /><br />

<p id = "p-black-20"><a href="https://bafybeifltqs4f3em3zd3ji3aon3had7rgtvbzqodetvl3skjkm2q3jce2u.ipfs.w3s.link/333.json" target="_blank">https://bafybeifltqs4f3em3zd3ji3aon3had7rgtvbzqodetvl3skjkm2q3jce2u.ipfs.w3s.link/333.json </a></p>

</div>





<br />


          </div>
          <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 py-0"></div>
          </div>
      </div>


        <br />

        <Footer />
        
      </section>
      
    </>
  
    );
};

export default Ipfs;