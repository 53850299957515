
import React from "react";
import { Link, useParams } from "react-router-dom";
import Vaults from "../../site/json/vaults_501_1000/vaults_enc_601_700.json";

import Footer from "../../pages/Footer";
import Notification from "../../pages/Notification";

const Explore_601_700 = () => {
  
  return (
    <>
      <section>

      <Notification />


              <div className ="container">
    <div className = "row">

<div className="col-6 col-sm-6 col-md-6 col-lg-10 col-xl-10 py-0"></div>
<div className="col-6 col-sm-6 col-md-6 col-lg-2 col-xl-2 py-2" align="center">
<div className="alert-dark-blue">
<Link to={"../explore/all"}> <p id="p-white-25"> All Vault(s) </p></Link>
</div></div>


</div></div>

<br />  

        <div className="container-fluid alert-blue-grotto py-0">
        <p id='p-white-25' align='center'>
           Vaults 601 - 700 
        </p>
        </div>

        <br />

      <div className ="container">
          <div className = "row">

              {Vaults.map((Vaults) => {
                return (

                <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 py-2" align='center'>
                  <div key={Vaults.id}>

                  <div className="WordDiv alert-light-blue"> <a href={Vaults.url}><p id="p-blue-25">  {Vaults.vault} </p></a> </div>

                  </div></div>
                );
              })}

          </div>
        </div>

        <br />

        <Footer />
        
      </section>
      
    </>
  
    );
};

export default Explore_601_700;